import { ConfigInterface, keyInterface } from 'swr/dist/types'
import useSWR from 'swr'
import { fetchJson } from '~/utils/fetch'
import { useSelector } from '~/reducers'
import { useTranslation } from '~/i18n'

const fetcher = (url: string, token?: string, language?: string) => {
  return fetchJson(url, token, language)
}

function useFetch<Data = any, Error = any>(
  key: keyInterface,
  config?: ConfigInterface<Data, Error>
) {
  const token = useSelector((state) => state.auth.token)
  const { i18n } = useTranslation()
  const { data, error, isValidating, mutate } = useSWR<Data, Error>(
    [key, token, i18n.language],
    fetcher,
    config
  )
  return {
    data,
    error,
    isValidating,
    mutate,
    fetcher: (url: string) => fetcher(url, token, i18n.language),
  }
}

export default useFetch
