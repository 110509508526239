import React from 'react'
import dynamic from 'next/dynamic'

const Map = dynamic(() => import('~/components/Map'), {
  ssr: false,
})

class MapErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log('error', { error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}

export default (props: any) => {
  return (
    <MapErrorBoundary>
      <Map {...props} />
    </MapErrorBoundary>
  )
}
